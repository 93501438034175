<template>
  <div class="presentations template-1">
    <header class="page-header container">
      <h1>{{ translations.tcPlacementDates }}</h1>
    </header>
    <security-banner v-if="!allowPage" :i18n="translations.components"></security-banner>
    <div v-if="allowPage">
      <!-- / Page Header -->
      <camp-selector @camp_changed="page_load($event)" :tooltip="translations.tcTemporarilyChangeCamps"
        :i18n="translations.components"></camp-selector>
      <!-- / Camp Selector -->
      <data-table :fields="upcoming_fields" :items="upcoming_placements" :addItems="addPlacement"
        @addPlacementDate="addPlacementDate" :title="translations.tcUpcomingPlacementDates" :toggle="true"
        :csvUrl="upcomingPlacementDatesAsCSVUrl" :pdfUrl="upcomingPlacementDatesAsPDFUrl"
        :reportName="upcomingReportName" :i18n="translations.components"></data-table>
      <!-- / Data Tables -->
      <a id="pastdates" />
      <data-table :fields="past_fields" :items="recent_placements" :addItems="addPlacement"
        @addPlacementDate="addPlacementDate" :title="translations.tcPastPlacementDates" :toggle="true"
        :csvUrl="pastPlacementDatesAsCSVUrl" :pdfUrl="pastPlacementDatesAsPDFUrl" :reportName="pastReportName"
        :i18n="translations.components"></data-table>
      <!-- / Data Tables -->
      <footer class="page-footer"></footer>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import CampSelect from '@/components/CampSelect.vue'
import DataTable from '@/components/DataTableScripture.vue'
import SecurityBanner from '@/components/SecurityBanner'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'placement-dates',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      pastReportName: 'PastPlacementDates',
      upcomingReportName: 'UpcomingPlacementDates',
      secured_controls: {
        add_scripture_placement_facility_dates_button: '13588ae8-9c7b-4b8e-938a-e7eaa7cd7c7f',
      },
      hiddenItems: {
        display: false,
      },
      upcoming_fields: [
        {
          key: 'placement_date',
          label: '', // 'Date'
          sortable: true,
          thClass: 'date-left',
          formatter: (value, key, item) => {
            return value.slice(0, 10)
          },
        },
        {
          key: 'facility_name',
          label: '', // 'Name'
          sortable: true,
        },
        { key: 'city', label: 'City', sortable: true },
      ],
      past_fields: [
        {
          key: 'placement_date',
          label: '', // 'Date'
          sortable: true,
          thClass: 'date-left',
          formatter: (value, key, item) => {
            return value.slice(0, 10)
          },
        },
        {
          key: 'facility_name',
          label: '', // 'Name'
          sortable: true,
        },
        {
          key: 'city',
          label: '', // 'City'
          sortable: true,
        },
        {
          key: 'quantity_placed',
          label: '', // 'Scriptures placed'
          sortable: true,
        },
      ],
    }
  },
  methods: {
    ...mapActions({
      clearLocationDetails: 'scriptureDistribution/clearLocationDetails',
      clearPlacementDateDetails: 'scriptureDistribution/clearPlacementDateDetails',
      loadPastPlacementDatesCSVUrl: 'scriptureDistribution/loadPastPlacementDatesCSVUrl',
      loadPastPlacementDatesPDFUrl: 'scriptureDistribution/loadPastPlacementDatesPDFUrl',
      loadPlacementDatesByCampLast12: 'scriptureDistribution/loadPlacementDatesByCampLast12',
      loadUpcomingPlacementDatesCSVUrl: 'scriptureDistribution/loadUpcomingPlacementDatesCSVUrl',
      loadUpcomingPlacementDatesPDFUrl: 'scriptureDistribution/loadUpcomingPlacementDatesPDFUrl',
      setLoadingStatus: 'menu/setLoadingStatus',
      setRouterBackLink: 'user/setRouterBackLink',
      setSelectedLocationKey: 'user/setSelectedLocationKey',
      setSelectedPlacementDateKey: 'user/setSelectedPlacementDateKey',
    }),
    async page_load(evt) {
      if (!evt) { // execute only if evt is undefined
        try {
          await this.setLoadingStatus(true)
          await Promise.all([
            await this.loadPlacementDatesByCampLast12(),
            await this.loadPastPlacementDatesCSVUrl(),
            await this.loadPastPlacementDatesPDFUrl(),
            await this.loadUpcomingPlacementDatesCSVUrl(),
            await this.loadUpcomingPlacementDatesPDFUrl()
          ]).then(() => true)
        } catch (e) {
          console.error(e)
        } finally {
          await this.setLoadingStatus(false)
        }
      }
    },
    async addPlacementDate() {
      await this.setSelectedLocationKey('')
      await this.clearLocationDetails()
      await this.setSelectedPlacementDateKey('')
      await this.clearPlacementDateDetails('')
      await this.setRouterBackLink('placement-dates')
      this.$router.push('/programs/sd/add-placement-date')
    },
    loadDataTableFields() {
      this.upcoming_fields[0].label = this.translations.tcDate
      this.upcoming_fields[1].label = this.translations.tcName
      this.upcoming_fields[2].label = this.translations.tcCity
      this.past_fields[0].label = this.translations.tcDate
      this.past_fields[1].label = this.translations.tcName
      this.past_fields[2].label = this.translations.tcCity
      this.past_fields[3].label = this.translations.tcScripturesPlaced
    },
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      await Promise.all([
        this.getViewTranslations(),
        this.getComponentTranslations('security-banner', 'camp-select', 'data-table-scripture'),
      ]).then((results) => {
        const componentTranslations = results[1]
        this.$set(this.translations, 'components', componentTranslations)
        this.loadDataTableFields()
        this.page_load()
      }).then(() => true)
    } catch (e) {
      console.error(e)
    } finally {
      await this.setLoadingStatus(false)
    }
  },
  computed: {
    ...mapGetters({
      iCanSee: 'user/iCanSee',
      isInMenu: 'menu/isInMenu',
      pastPlacementDatesAsCSVUrl: 'scriptureDistribution/pastPlacementDatesAsCSVUrl',
      pastPlacementDatesAsPDFUrl: 'scriptureDistribution/pastPlacementDatesAsPDFUrl',
      prefCulture: 'user/userPreferredCulture',
      recent_placements: 'scriptureDistribution/recent_placements',
      upcoming_placements: 'scriptureDistribution/upcoming_placements',
      upcomingPlacementDatesAsCSVUrl: 'scriptureDistribution/upcomingPlacementDatesAsCSVUrl',
      upcomingPlacementDatesAsPDFUrl: 'scriptureDistribution/upcomingPlacementDatesAsPDFUrl',
    }),
    allowPage() {
      return this.isInMenu(this.$route.path)
    },
    addPlacement() {
      return this.iCanSee(this.secured_controls.add_scripture_placement_facility_dates_button)
        ? { display: true, text: this.translations.tcAddPlacement, action: 'addPlacementDate' }
        : {}
    },
  },
  components: {
    campSelector: CampSelect,
    dataTable: DataTable,
    securityBanner: SecurityBanner,
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
@import '@/styles/views/ChurchList.scss';
</style>
